import React from "react";
import "./OurServices.css";
import heroWave from "../images/wave-hero.svg";
import Roller from "../images/roller.svg";
import Painter from "../images/painter.svg";
import Handyman from "../images/hamwrench.svg";

const OurServices = () => {
  return (
    <>
      <div className="our-services-container">
        <img className="heroWave" src={heroWave} alt="" />
        <div className="our-services-details">
          <div className="our-services-title">Our Services</div>
          <div className="our-services-list">
            <div className="our-services-residential">
              <div className="residential-container">
                <div className="our-services-residential-title">
                  Residential Painting
                </div>
                <div className="our-services-residential-description">
                  We create inviting and personalised spaces, turning houses
                  into dream homes with our meticulous craftsmanship.
                </div>
              </div>
              <img src={Painter} className="painterimage" alt="" />
            </div>
            <div className="our-services-commercial">
              <img className="rollerimage" src={Roller} alt="" />
              <div className="commercial-container">
                <div className="our-services-commercial-title">
                  Commercial Painting
                </div>
                <div className="our-services-commercial-description">
                  We transform businesses with vibrant colors and expert
                  finishes, enhancing their professional image.
                </div>
              </div>
            </div>
          </div>
          <div className="handyman-holder">
            <div className="our-services-handyman">
              <img src={Handyman} className="handyimage" alt="" />
              <div className="handyman-container">
                <div className="our-services-handyman-title">
                  Property Maintenance
                </div>
                <div className="our-services-handyman-description">
                  From small repairs to larger tasks, we ensure your home or
                  business stays in perfect condition. Reliable, skilled, and
                  ready to tackle your to-do list.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;
