import AboutUs from "../aboutus/AboutUs";
import ContactPanel from "../contact/ContactPanel";
import Home from "./Home";
import OurServices from "../services/OurServices";

function FullHome() {
  return (
    <div className="fullhome-container">
      <div id="homenav">
        <Home />
        <OurServices />
      </div>
      <div id="aboutnav">
        <AboutUs />
      </div>
      <div id="contactnav">
        <ContactPanel />
      </div>
    </div>
  );
}

export default FullHome;
