import React from "react";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="notfound-container">
      <div className="notfound-title">Oops...</div>
      <div className="notfound-description">This page was not found.</div>
    </div>
  );
};

export default NotFound;
