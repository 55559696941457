import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Logo from "../images/VBLogo.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="find-us-container">
        <div className="find-us-title">Find Us:</div>
        <div className="find-us-mail">
          <a
            className="email-button-footer"
            href="mailto:email@vbhomeenhancement.co.uk"
          >
            {" "}
            email@vbhomeenhancement.co.uk{" "}
          </a>
        </div>
        <div className="phone-holder">
          <a className="call-button-footer" href="tel:+447415209274">
            {" "}
            +44 741 5209 274{" "}
          </a>
        </div>
      </div>
      <div className="footer-right-container">
        <div className="logo-holder-footer">
          {" "}
          <img className="footerlogo" src={Logo} alt="" />
          <span className="footer-logo-title">Home Enhancement</span>
        </div>
        <div className="privacy-policy-footer">
          <Link className="link" to="/privacy-policy">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
