import React from "react";
import "./Home.css";
import HeroImage from "../images/hero-image.png";

const Home = () => {
  const handleMenuClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = -90; // Offset for the navbar height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      console.error(`Element with id ${id} not found`);
    }
  };
  return (
    <div className="hero-container">
      <div className="home-action-panel">
        <div className="hero-title">Your Vision, Our Work.</div>
        <div className="hero-description">
          We bring your ideas to life with our professional painting,
          decorating, and property maintenance services, ensuring every detail
          is handled with care and expertise.
        </div>
        <div
          className="hero-action-button"
          onClick={() => handleMenuClick("contactnav")}
        >
          <span>Request a Quote</span>
        </div>
      </div>
      <div className="home-hero-image">
        <img className="hero-image-sizer" src={HeroImage} alt="" />{" "}
      </div>
    </div>
  );
};

export default Home;
