import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/navigation/Navbar";
import Footer from "./components/footer/Footer";
import FullHome from "./components/hero/FullHome";
import PrivacyPolicy from "./components/policies/PrivacyPolicy";
import NotFound from "./components/policies/NotFound"; // Create a NotFound component

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<FullHome />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route element={<NotFound />} path="*" /> {/* Route for 404 page */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
