import React from "react";
import "./AboutUs.css";
import AboutUsImage from "../images/aboutus.jpg";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-title">About Us</div>
      <div className="about-us-panel-container">
        <div className="about-us-panel">
          <div className="about-us-panel-description">
            <span>
              At VB Home Enhancement, we believe in the power of transformation
              through color, design, and skilled craftsmanship. With years of
              experience and certified staff, our team brings your vision to
              life.
              <br />
            </span>
            <span>
              <br />
              We ensure every project reflects our commitment to quality and
              excellence. From painting and decorating to comprehensive property
              maintenance, trust us to handle your needs with utmost care and
              expertise, creating spaces that truly inspire.
            </span>
          </div>
        </div>
        <img className="about-us-page" src={AboutUsImage} alt="" />
      </div>
    </div>
  );
};

export default AboutUs;
