import React, { useState, useEffect, useRef } from "react";
import { Divide as Hamburger } from "hamburger-react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import Logo from "../images/VBLogo.png";
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1090);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1090);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = -90; // Offset for the navbar height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      setIsOpen(false);
    } else {
      console.error(`Element with id ${id} not found`);
    }
  };

  const handleClickOutside = (event) => {
    if (isOpen && menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // Function to scroll to the top of the page when already at the home screen
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsOpen(false);
  };

  return (
    <div className="navbar-wrapper">
      <div className="navbar-container">
        <div className="navbar-logo">
          {" "}
          <Link className="link logo-container" to="/" onClick={scrollToTop}>
            <img className="logoclass" src={Logo} alt="" />
            <span className="logo-title">Home Enhancement</span>
          </Link>
        </div>
        {isMobile ? (
          <div className="hamburger-menu-wrapper">
            <div onClick={() => setIsOpen(!isOpen)} className="hamburger-icon">
              <Hamburger toggled={isOpen} toggle={setIsOpen} />
            </div>
            <div ref={menuRef}>
              <Menu
                right
                isOpen={isOpen}
                onStateChange={({ isOpen }) => setIsOpen(isOpen)}
                noOverlay
                className="bm-menu"
              >
                <li className="bm-item navbar-home">
                  <Link className="link" to="/" onClick={scrollToTop}>
                    Home
                  </Link>
                </li>
                <li
                  onClick={() => handleMenuClick("aboutnav")}
                  className="bm-item navbar-about-us"
                >
                  About Us
                </li>
                <li
                  onClick={() => handleMenuClick("contactnav")}
                  className="bm-item navbar-contact-us"
                >
                  Contact Us
                </li>
                <li
                  onClick={() => handleMenuClick("contactnav")}
                  className="bm-item navbar-request-quote"
                >
                  Request a Quote
                </li>
              </Menu>
            </div>
          </div>
        ) : (
          <>
            <div className="navbar-entries">
              <li className="navbar-home">
                <Link className="link" to="/" onClick={scrollToTop}>
                  Home
                </Link>
              </li>
              <li
                onClick={() => handleMenuClick("aboutnav")}
                className="navbar-about-us"
              >
                About Us
              </li>
              <li
                onClick={() => handleMenuClick("contactnav")}
                className="navbar-contact-us"
              >
                Contact Us
              </li>
            </div>
            <div
              className="navbar-action-button"
              onClick={() => handleMenuClick("contactnav")}
            >
              <span>Request a Quote</span>
            </div>
          </>
        )}
      </div>
      <div className="border-container">
        <div className="border"></div>
      </div>
    </div>
  );
};

export default Navbar;
